import PropTypes from 'prop-types';
import { ListLinks } from 'bv-components';
import { t } from 'bv-i18n';

const LinksComponent = ({
  className, linkComponent: LinkComponent, links, tKey,
}) => {
  if (!links.length) return null;

  return (
    <section className={className}>
      {tKey && <h3 className="bvs-header">{t(tKey)}</h3>}
      <ListLinks>
        {links.map((link) => <LinkComponent link={link} key={link.id} />)}
      </ListLinks>
    </section>
  );
};

LinksComponent.propTypes = {
  className: PropTypes.string,
  linkComponent: PropTypes.elementType.isRequired,
  links: PropTypes.instanceOf(Array).isRequired,
  tKey: PropTypes.string,
};

LinksComponent.defaultProps = {
  className: '',
  tKey: null,
};

export default LinksComponent;
