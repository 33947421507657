import LinksComponent from './links_component';
import PageLink from './links/page_link';

const Pages = (props) => (
  <LinksComponent
    className="home-list-component"
    linkComponent={PageLink}
    {...props}
  />
);

export default Pages;
