import { useFetch } from 'bv-hooks';

import fetchHome from './api/fetch_home';
import { Highlights, Pages } from './components';

const LegacyHomeComponents = () => {
  const [data, loading] = useFetch(fetchHome);

  if (loading) return null;

  return (
    <section className="legacy-home-components-wrapper">
      <Highlights links={data.front_page_items} />
      <Pages links={data.pages} />
    </section>
  );
};

export default LegacyHomeComponents;
