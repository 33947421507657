import LinksComponent from './links_component';
import SportLink from './links/sport_link';

const Highlights = (props) => (
  <LinksComponent
    linkComponent={SportLink}
    tKey="highlights"
    className="home-list-component"
    {...props}
  />
);

export default Highlights;
