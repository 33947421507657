import PropTypes from 'prop-types';
import { ListRow } from 'bv-components';

const Link = ({ link }) => {
  const opts = link.full_url.startsWith('http')
    ? { href: link.full_url }
    : { to: `/pages/${link.path}` };

  return (
    <ListRow
      {...opts}
      label={link.title}
      hasArrow
    />
  );
};

Link.propTypes = {
  link: PropTypes.instanceOf(Object).isRequired,
};

export default Link;
