import { meetingPath, preEventPath, sportPath } from 'bv-helpers/routes';

export const linkSportId = (link) => (
  link.sport_id || link.sport_agp_id
);

// This method is very ugly, just following the same logic from mobile
// Sometimes checking the kind, sometimes other fields...
export const linkPath = (link) => {
  if (link.special) {
    return {
      'live-betting': '/live',
      'all-sports': '/sports',
    }[link.key];
  }

  if (link.event_id) {
    return preEventPath({
      sportEventPathId: link.sport_id,
      meetingId: link.meeting_id,
      eventId: link.event_id,
    });
  }

  if (link.meeting_id) {
    return meetingPath({
      sportEventPathId: link.sport_id,
      meetingId: link.meeting_id,
    });
  }

  return sportPath(link.sport_id);
};
