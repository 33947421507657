import { memo } from 'react';
import { ContentLayout } from 'bv-components';
import { HomeComponentsBanners, HomeComponents, LegacyHomeComponents } from './components';

const HomeView = memo(() => (
  <ContentLayout showTabs>
    <HomeComponentsBanners />
    <HomeComponents />
    <LegacyHomeComponents />
  </ContentLayout>
));

export default HomeView;
