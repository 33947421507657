import PropTypes from 'prop-types';
import { Icon, ListRow } from 'bv-components';
import { t } from 'bv-i18n';

import { linkSportId, linkPath } from './helpers';

const Link = ({ link }) => (
  <ListRow
    to={linkPath(link)}
    hasArrow
    label={(
      <>
        <Icon className="sport-icon" id={`sport-${linkSportId(link)}`} type="list-sport-icon" />
        {t(link.title)}
      </>
      )}
  />
);

Link.propTypes = {
  link: PropTypes.instanceOf(Object).isRequired,
};

export default Link;
