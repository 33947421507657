import { useFetch } from 'bv-hooks';
import withLazyLoad from 'bv-lazy-load';
import fetchComponents from './api';

const HomeComponentsLazy = withLazyLoad()('homeComponents');

const HomeComponentsContainer = () => {
  const [components, loading] = useFetch(fetchComponents);

  if (loading) return <div className="home-components-container" />;
  if (!components.length) return null;

  return <HomeComponentsLazy components={components} />;
};

export default HomeComponentsContainer;
